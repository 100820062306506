<template>
  <div>
    <el-page-header @back="$router.go(-1)"></el-page-header>
    <SATAnalysis v-if="analysis" type="adaptive" :analysis="analysis"></SATAnalysis>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SATAnalysis from "@/views/courses/components/SAT";
import { instant } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import Class from "@/apis/class.js";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: { SATAnalysis },

  mixins: [Common],

  props: [],
  data() {
    return {
      analysis: null
    };
  },
  computed: {
    instant() {
      return instant;
    },
    myTitle() {
      return "";
    },
    week() {
      return this.$route.query.week;
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.params.id) {
      this.getClass(this.$route.params.id);
    }
  },

  methods: {
    async getClass(id) {
      const res = await Class.getAdaptiveExamsDetail(id, {
        session_class_exam_id: this.$route.query.session_class_exam_id
      });
      this.analysis = { answers: res.analysis };
    }
  }
};
</script>

<style scoped>
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-top: 60px;
}
.box .left-menu {
  width: 200px;
  height: 100%;
}
.box .right-content {
  background: #fafafa;
}
.right-content-detail {
  width: inherit;
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 20px !important;
  padding-bottom: 20px;
}
.button-group {
  padding: 1rem 20px;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .button-group .el-button {
  width: 100%;
  margin: 0.5rem 0;
}
.right-content {
  flex: 1;
  height: 100%;
  overflow: auto;
}
</style>
